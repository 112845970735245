<template>
  <div class="w-full z-10">
    <div class="header">
      <div class="d-flex">
        <h2 class="page-title" style="margin-left: 2rem !important;">
          {{ title }}
        </h2>
        <div class="d-flex order-lg-2 ml-auto">
          <div class="dropdown">
            <a href="#" class="nav-link pr-0 leading-none mr-2" data-toggle="dropdown">
              <span class="avatar" :style="{ 'background-image': 'url('+vicon+')' }"></span>
              <span class="ml-2 d-none d-lg-block">
                <span class="text-default">{{ $store.getters.currentMyProfile.name }}</span>
                <small class="text-muted d-block mt-1">{{ $store.getters.currentMyProfile.role.name }}</small>
              </span>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
              <a class="dropdown-item" href="#" @click="showModalChangePassword = true">
                <font-awesome-icon :icon="['fas', 'lock']"/>&nbsp; Change Password
              </a>
              <a class="dropdown-item cursor-pointer" @click.prevent="gotoAdmin">
                <font-awesome-icon :icon="['fas', 'users-cog']"/>&nbsp; Admin Area
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click="doLogout">
                <font-awesome-icon :icon="['fas', 'sign-out-alt']"/>&nbsp; Sign out
              </a>
            </div>
          </div>
        </div>
        <a href="#" class="header-toggler d-lg-none ml-3 ml-lg-0" data-toggle="collapse"
          data-target="#headerMenuCollapse">
          <span class="header-toggler-icon"></span>
        </a>
      </div>
    </div>
    <b-modal size="md" v-model="showModalChangePassword" title="Change Password" hide-footer>
      <b-form @submit.prevent="confirmUpdatePassword" @reset="resetModalChangePassword">
        <b-form-group label="Old Password">
          <b-input-group>
            <template #append>
              <b-input-group-text class="cursor-pointer" @click="showPassword('old_password')">
                <span v-if="input_type.old_password === 'text'"><font-awesome-icon icon="eye"/></span>
                <span v-else><font-awesome-icon icon="eye-slash"/></span>
              </b-input-group-text>
            </template>
            <b-form-input
              ref="old_password"
              v-model="v$.form.old_password.$model"
              placeholder="Input your old password"
              :type="input_type.old_password"/>
          </b-input-group>
          <b-form-invalid-feedback :state="!v$.form.old_password.$invalid" v-if="v$.form.old_password.$errors[0]">
            <span> {{ v$.form.old_password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="New Password">
          <b-input-group>
            <template #append>
              <b-input-group-text class="cursor-pointer" @click="showPassword('new_password')">
                <span v-if="input_type.new_password === 'text'"><font-awesome-icon icon="eye"/></span>
                <span v-else><font-awesome-icon icon="eye-slash"/></span>
              </b-input-group-text>
            </template>
            <b-input
              v-model="v$.form.new_password.$model"
              placeholder="Input your new password"
              :type="input_type.new_password"/>
          </b-input-group>
          <b-form-invalid-feedback :state="!v$.form.new_password.$invalid" v-if="v$.form.new_password.$errors[0]">
            <span>{{ v$.form.new_password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="Confirm New Password">
          <b-input-group>
            <template #append>
              <b-input-group-text class="cursor-pointer" @click="showPassword('confirm_password')">
                <span v-if="input_type.confirm_password === 'text'"><font-awesome-icon icon="eye"/></span>
                <span v-else><font-awesome-icon icon="eye-slash"/></span>
              </b-input-group-text>
            </template>
            <b-input
              v-model="v$.form.confirm_password.$model"
              placeholder="Re-input your new password"
              :type="input_type.confirm_password"/>
          </b-input-group>
          <b-form-invalid-feedback :state="!v$.form.confirm_password.$invalid" v-if="v$.form.confirm_password.$errors[0]">
            <span v-if="v$.form.confirm_password.$errors[0].$validator == 'sameAs'">Password not match with new password</span>
            <span v-else> {{ v$.form.confirm_password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex flex-row float-right">
          <el-button @click="confirmUpdatePassword" :loading="loading.change_password" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
          <el-button @click="closeModalChangePassword" size="small" class="mr-2">Cancel</el-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import {
  required, sameAs, minLength, helpers,
} from '@vuelidate/validators';
import { LOGOUT, CHANGE_PASSWORD } from '@/store/modules/auth';
import vicon from '@/assets/images/v-icon.png';
import base64RSA from '@/library/rsa-encrypt';
import popupErrorMessages from '@/library/popup-error-messages';
import passwordComplexity from '@/library/password-complexity';

export default {
  name: 'Navbar',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  metaInfo() {
    return {
      titleTemplate: (s) => {
        this.title = s;
        return (s ? `${s} | V-Dashboard` : 'V-Dashboard');
      },
    };
  },
  data() {
    return {
      title: '',
      vicon,
      showModalChangePassword: false,
      loading: {
        change_password: false,
      },
      form: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      input_type: {
        old_password: 'password',
        new_password: 'password',
        confirm_password: 'password',
      },
    };
  },
  validations() {
    return {
      form: {
        old_password: { required },
        new_password: {
          required,
          minLength: minLength(8),
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        confirm_password: { required, sameAs: sameAs(this.form.new_password) },
      },
    };
  },
  computed: {
  },
  methods: {
    async doLogout() {
      const loader = this.$loading.show();
      await this.$store.dispatch(LOGOUT);
      loader.hide();
    },
    showPassword(target) {
      if (this.input_type[target] === 'text') {
        this.input_type[target] = 'password';
      } else if (this.input_type[target] === 'password') {
        this.input_type[target] = 'text';
      }
    },
    confirmUpdatePassword() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      this.$confirm('Are you want to change your current password?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            done();
            this.updatePassword();
          }
          done();
        },
      }).catch(() => {});
    },
    gotoAdmin() {
      this.$router.push('/internal-users').catch();
    },
    async updatePassword() {
      this.loading.change_password = true;
      await this.$store.dispatch(CHANGE_PASSWORD, {
        old_password: base64RSA.encrypt(this.form.old_password).toString('base64'),
        new_password: base64RSA.encrypt(this.form.new_password).toString('base64'),
        confirm_password: base64RSA.encrypt(this.form.confirm_password).toString('base64'),
      }).then(() => {
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Password updated',
        });
        this.showModalChangePassword = false;
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
      this.loading.change_password = false;
    },
    resetModalChangePassword() {
      this.form = {
        old_password: '',
        new_password: '',
        confirm_password: '',
      };
    },
    closeModalChangePassword() {
      this.showModalChangePassword = false; 
    },
  },
};
</script>
