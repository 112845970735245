<template>
  <div>
    <!-- <Navbar /> -->
    <div class="wrapper">
      <Sidebar ref="sidebar"/>
      <div class="page-wrapper h-screen">
        <navbar/>
        <div class="container-xl">
          <!-- Page title -->
          <!-- <div class="page-header d-print-none">
            <h2 class="page-title">
              {{ pageTitle }}
            </h2>
          </div> -->
        </div>
        <div class="page-body">
          <slot />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';

export default {
  name: 'DashboardLayout',
  data() {
    return {
      loader: null,
      loaderStack: 0,
    };
  },
  mounted() {
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
  components: {
    Footer: () => import('../components/Footer.vue'),
    // Navbar: () => import('../components/Navbar.vue'),
    Sidebar: () => import('../components/Sidebar.vue'),
    Navbar,
  },
};
</script>
